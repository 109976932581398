import React, { useState, useEffect, useCallback } from 'react';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import { CircularProgress } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import PlayingSequence from 'components/PlayingSequence';
import { BsPlay } from 'react-icons/bs';
import './DashBoardHome.scss';
import { getCookie, setCookie } from '../../utils/helper';

const DASHBOARD_CONTAINER_CLASSNAME = 'dashboard-home-container';

const DashboardHome = () => {
  const [loading, setLoading] = useState(true);
  const [sequenceGuid, setSequenceGuid] = useState('');
  const [sequencePlay, setSequencePlay] = useState('');
  const [sequence, setSequence] = useState({});
  const [studyName, setStudyName] = useState('');
  const [studyEndDateBanner, setStudyEndDateBanner] = useState('');
  const [daysBeforeTodayArray, setDaysBeforeTodayArray] = useState([]);
  const [totalDaysElapsed, setTotalDaysElapsed] = useState(0);
  const [daysAfterTodayArray, setDaysAfterTodayArray] = useState([]);
  const [canShowTodaysButton, setCanShowTodaysButton] = useState(false);

  const axiosInstance = useAxios(NEUX_URL);

  function getOrdinalSuffix(day) {
    if (day % 10 === 1 && day % 100 !== 11) return `${day}st`;
    if (day % 10 === 2 && day % 100 !== 12) return `${day}nd`;
    if (day % 10 === 3 && day % 100 !== 13) return `${day}rd`;
    return `${day}th`;
  }

  function formatStudyEndDate(endDate) {
    const day = getOrdinalSuffix(endDate.getUTCDate());
    const month = endDate.toLocaleString('en-US', {
      month: 'long',
      timeZone: 'UTC',
    });
    const year = endDate.getUTCFullYear();
    const time = `${endDate
      .getUTCHours()
      .toString()
      .padStart(2, '0')}:${endDate
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}`;
    return `${day} of ${month} ${year} ${time} UTC`;
  }

  function createStudyEndDateBanner(endDate) {
    const formattedEndDate = formatStudyEndDate(endDate);
    const currentDate = new Date();
    if (currentDate > endDate) {
      return 'This study ended on ' + formattedEndDate;
    }
    return 'Must be completed by ' + formattedEndDate;
  }

  const onLoadData = useCallback(async () => {
    try {
      const cachedStudyGuid = getCookie('study_guid');
      const userInfoResp = await axiosInstance.get('/sc/user/info', {
        params: { study_guid: cachedStudyGuid },
      });
      setCookie('study_guid', '');
      const studyGuid = userInfoResp?.data?.study_guid;
      const dateJoinedStudy = userInfoResp?.data?.date_joined_study;
      if (studyGuid && dateJoinedStudy) {
        const studyResp = await axiosInstance.get('/sc/study/' + studyGuid);
        setStudyName(studyResp?.data?.name || '');

        const rawStudyB64 = studyResp?.data.data;
        if (rawStudyB64) {
          // compute and format the study end date.
          const rawStudyJson = atob(rawStudyB64);
          const studyJson = JSON.parse(rawStudyJson);
          const startDate = new Date(dateJoinedStudy);
          const endDate = new Date(startDate);
          const maxParticipationDays = studyJson.duration_days;
          endDate.setUTCDate(
            startDate.getUTCDate() + studyJson?.duration_calendar_days,
          );
          endDate.setUTCHours(23, 59, 0, 0);
          const studyEndDateBannerValue = createStudyEndDateBanner(endDate);
          setStudyEndDateBanner(studyEndDateBannerValue);

          // Calculate days the study has been running (excluding today)
          let daysRunning =
            userInfoResp?.data?.days_participated_before_today || 0;

          daysRunning = Math.min(daysRunning, maxParticipationDays);

          const runningDaysArray = Array.from(
            { length: daysRunning },
            (_, i) => i + 1,
          );
          setDaysBeforeTodayArray(runningDaysArray);

          // Calculate total days passed (including today)
          const totalDaysElapsedValue = daysRunning + 1;
          setTotalDaysElapsed(totalDaysElapsedValue);

          const canShowTodaysButtonValue =
            totalDaysElapsedValue <= maxParticipationDays;
          setCanShowTodaysButton(canShowTodaysButtonValue);

          if (canShowTodaysButtonValue) {
            // Calculate days remaining (excluding today)
            const futureDaysArray = Array.from(
              { length: maxParticipationDays - totalDaysElapsedValue },
              (_, i) => totalDaysElapsedValue + i + 1,
            );
            setDaysAfterTodayArray(futureDaysArray);
          }
        }
      }

      const resp = await axiosInstance.get('/sc/user/sequenceplay-dispatch');
      if (resp?.data?.sequence_guid) {
        setSequenceGuid(resp?.data?.sequence_guid);
        setSequencePlay(resp?.data?.sequenceplay_guid);
        return resp?.data?.sequence_guid;
      }
    } catch (error) {
      console.log('error', error);
    }
    return null;
  }, [axiosInstance]);

  const fetchDefaultData = useCallback(async () => {
    setLoading(true);
    const guid = await onLoadData();
    if (!guid) {
      setTimeout(async () => {
        await onLoadData();
      }, 2000);
    }
    setLoading(false);
  }, [onLoadData]);

  const onPlaySequence = async guid => {
    try {
      const { data } = await axiosInstance.get(`/sc/sequence/${guid}`);
      if (data) {
        setSequence(data);
      }
      document.getElementsByClassName(
        DASHBOARD_CONTAINER_CLASSNAME,
      )[0].style.display = 'none';
    } catch (error) {
      /* eslint-disable-next-line no-console */
      setSequence({});
      console.log(error);
    }
  };

  const onClickPlaySequence = useCallback(async () => {
    try {
      await onPlaySequence(sequenceGuid);
      document.getElementById('game-outside').requestFullscreen();
    } catch (error) {
      console.log('error');
    }
  }, [sequenceGuid]);

  const onCloseSequence = useCallback(async () => {
    const guid = await onLoadData();
    if (guid) {
      await onPlaySequence(guid);
    } else {
      window.location.reload(); // reset data
    }
  }, [onLoadData, onPlaySequence]);

  useEffect(() => {
    if (axiosInstance) {
      fetchDefaultData();
    }
    window.addEventListener('SEND_USER_INFO', fetchDefaultData);
    return () => {
      window.removeEventListener('SEND_USER_INFO', fetchDefaultData);
    };
  }, [axiosInstance]);

  return (
    <>
      <div id="game-outside">
        {loading ? (
          <div className="sequence-item-loading">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={DASHBOARD_CONTAINER_CLASSNAME}>
              <h2>{studyName}</h2>
              <h4>{studyEndDateBanner}</h4>

              {!canShowTodaysButton ? (
                <h4>All tasks are now completed.</h4>
              ) : null}

              {!sequenceGuid || !sequencePlay ? (
                <h4>All tasks are now completed for today</h4>
              ) : null}

              <div className="dayButtonsWrapper">
                {daysBeforeTodayArray.map(day => (
                  <button
                    key={day}
                    type="button"
                    className="btn btn-primary"
                    disabled
                  >
                    <BsPlay fontSize="30px" />
                    <span>Day {day}</span>
                  </button>
                ))}

                {/* eslint-disable-next-line no-nested-ternary */}
                {studyName ? (
                  canShowTodaysButton ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={!sequenceGuid || !sequencePlay}
                      onClick={onClickPlaySequence}
                    >
                      <BsPlay fontSize="30px" />
                      <span>Day {totalDaysElapsed}</span>
                    </button>
                  ) : null
                ) : (
                  <h2>This user is not assigned to a study.</h2>
                )}

                {daysAfterTodayArray.map(day => (
                  <button
                    key={day}
                    type="button"
                    className="btn btn-primary"
                    disabled
                  >
                    <BsPlay fontSize="30px" />
                    <span>Day {day}</span>
                  </button>
                ))}
              </div>
            </div>
            <PlayingSequence
              sequence={sequence}
              sequencePlayGuid={sequencePlay}
              onClose={onCloseSequence}
              isKeepingGameMode={!isEmpty(sequence)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DashboardHome;
