import React, { useState } from 'react';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import { FaFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import './UpdateStudyDash.scss';

// const downloadExcelFile = (result, name) => {
//   const element = document.createElement('a');
//   const file = new Blob([result.data], {
//     type: result.headers['content-type'],
//   });
//   element.href = URL.createObjectURL(file);
//   element.download = name;
//   element.click();
// };

export default ({ id, study, onToggleStudy }) => {
  const axiosInstance = useAxios(NEUX_URL);
  const [isLoading, setLoading] = useState(false);

  const onDownload = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `sc/study/${id}/xlsx/?tabs=sequenceplays,rawsequence,generalreport`,
        {
          responseType: 'application/json',
        },
      );
      toast.success('You will receive an email with the attached data.');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="update-study-dash-container">
      <div className="update-study-dash-container__header">
        <div className="update-study-dash-container__header__row">
          <div className="update-study-dash-container__header__row__label">
            Open/Close:
          </div>
          <button
            onClick={onToggleStudy}
            type="button"
            className="btn btn-primary download-study-btn"
            disabled={isLoading}
          >
            <div>{study?.is_open ? 'Close Study' : 'Open Study'}</div>
          </button>
        </div>
        <div className="update-study-dash-container__header__row">
          <div className="update-study-dash-container__header__row__label">
            Download:
          </div>
          <button
            onClick={onDownload}
            type="button"
            className="btn btn-primary download-study-btn"
            disabled={isLoading}
          >
            <div>
              <FaFileExcel size={18} />
            </div>
            <div>Export (Excel) Download</div>
          </button>
        </div>
      </div>
    </div>
  );
};
